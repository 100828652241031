// @flow

import React, { useMemo, useContext, useReducer } from 'react';
import reducers from '../reducers';
import initialState from './initialState';
import type { State } from './initialState';

export default function makeStore() {
  // Make a context for the store
  const context = React.createContext();

  // Make a provider that takes an initialValue
  const Provider = ({ _initialState = initialState, children }) => {
    // Make a new state instance (could even use immer here!)
    const [store, dispatch] = useReducer(reducers, _initialState);

    // Memoize the context value to update when the state does
    const contextValue = useMemo(() => [store, dispatch], [store]);

    // Provide the store to children
    return <context.Provider value={contextValue}>{children}</context.Provider>;
  };

  // A hook to help consume the store
  const useStore = (): [State, Function] => useContext(context);

  return [Provider, useStore];
}

